<template>
  <div id="component">
    <div class="filter-panel">
      <input
          v-model="queryResAreaInfo.search"
          class="cs-input"
          placeholder="搜索责任区名称/备注"
          style="margin: 0; margin-bottom: 15px"
          type="text"
      />
      <CSSelect style="margin-left: 20px">
        <select v-model="queryResAreaInfo.type" style="width: 145px">
          <option value="">全部类型</option>
          <option
              v-for="type in judgmentAuthority(responsibilityAreaType)"
              :key="type.id"
              :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryResAreaInfo.principalUser" style="width: 145px">
          <option value="">全部负责人</option>
          <option v-for="satff in staffList" :key="satff.id" :value="satff.id">
            {{ satff.name }}
          </option>
        </select>
      </CSSelect>
      <button
          class="btn btn-primary"
          style="margin-left: 10px"
          type="button"
          @click="getResAreaList"
      >
        查询
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                type="button"
                @click="addResArea()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              责任区
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>责任区名称</th>
            <th>类型</th>
            <th>位置</th>
            <th>图片</th>
            <th>巡检点</th>
            <th>备注</th>
            <th>负责人</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="resArea in resAreaList" :key="resArea.id">
            <td class="date-td">
              {{ resArea.createTime }}
            </td>
            <td>
              {{ resArea.name }}
            </td>
            <td>
              {{
                resArea.type == 1
                    ? "保洁"
                    : resArea.type == 2
                        ? "保安"
                        : resArea.type == 3
                            ? "工程"
                            : resArea.type == 4
                                ? "客服"
                                : resArea.type == 5
                                    ? "其他"
                                    : ""
              }}
            </td>
            <td>
              {{ resArea.position }}
            </td>
            <td>
              <span class="allow-click" @click="lookImages(resArea.picture)">
                查看
              </span>
            </td>
            <td>
              <span
                  v-if="JSON.parse(resArea.pointIds).length != 0"
                  class="allow-click"
                  @click="toInspectionPoint(resArea.pointIds)"
              >
                {{ JSON.parse(resArea.pointIds).length }}
              </span>
              <span v-else> - </span>
            </td>
            <td>
              <span
                  v-if="resArea.comment != undefined"
                  class="allow-click"
                  @click="lookRemarks(resArea.comment)"
              >
                查看
              </span>
              <span v-else>-</span>
            </td>
            <td>
              {{ resArea.principalUserName }}
            </td>
            <td>
              {{ resArea.createUserName }}
            </td>
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 76px">
                  <li @click="editResArea(resArea)">
                    <a>修改</a>
                  </li>
                  <li @click="deleteResArea(resArea.id)">
                    <a>删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination componentName="Pagination" name="pagination"></Pagination>
    </div>
    <!-- 添加责任区对话框 -->
    <CSDialog
        :dialogTitle="dialogTitle"
        :dialogVisible="lookResponsibilityAreaVisibel"
        dialog-confirm-btn-text="保存"
        dialogWidth="916px"
        @click="InspectionPointInfo.treePanelVisible = false"
        @onClose="
        (lookResponsibilityAreaVisibel = false),
          (InspectionPointInfo.treePanelVisible = false)
      "
        @onConfirm="addEditResArea"
    >
      <div slot="dialog-content" class="add-addResArea-body">
        <div class="notice_box">
          <div class="notice_title">责任区名称</div>
          <input
              v-model="addResAreaInfo.name"
              class="notice_name"
              maxlength="10"
              placeholder="限10个字"
              type="text"
          />
        </div>
        <div class="notice_box">
          <div class="notice_title">类型</div>
          <CSSelect height="40px" iWidth="36px">
            <select
                v-model="addResAreaInfo.type"
                style="width: 215px; font-size: 24px; padding-left: 6px;color: #999999"
            >
              <option value="">请选择</option>
              <option
                  v-for="type in judgmentAuthority(responsibilityAreaType)"
                  :key="type.id"
                  :value="type.id"
              >
                {{ type.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div class="notice_box">
          <div class="notice_title">位置</div>
          <div style="vertical-align: middle; display: inline-block">
            <ChooseLocation
                :default-location-name="checkedLocationName"
                @changeLocation="changeLocation"
            />
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title">负责人</div>
          <CSSelect height="40px" iWidth="36px">
            <select
                v-model="addResAreaInfo.principalUser"
                style="width: 215px; font-size: 24px; padding-left: 6px;color: #999999"
            >
              <option value="">请选择</option>
              <option
                  v-for="staff in staffList"
                  :key="staff.id"
                  :value="staff.id"
                  style="color: #000"
              >
                {{ staff.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div>
          <div class="notice_title">图片</div>
          <div style="display: inline-block; margin-bottom: 20px">
            <div
                v-for="(item, index) in addResAreaInfo.picture"
                :key="index"
                class="photo-view"
                style="width: 80px; height: 80px"
            >
              <img :src="item" style="width: 80px; height: 80px"/>
              <i class="icon-close" @click="deletePhotos(index)"></i>
            </div>
            <div
                v-if="addResAreaInfo.picture.length <= 4"
                class="upload-photo"
                style="width: 80px; height: 80px"
                @click="triggerChoosePhoto"
            >
              <div class="text-center">
                <img
                    alt=""
                    src="@/assets/upload.png"
                    style="margin: 5px auto 5px"
                />
                <p style="color: #999">上传照片</p>
              </div>
            </div>
            <input
                id="uploadStaffPhoto"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="chooseStaffPhoto($event)"
            />
          </div>
        </div>
        <div class="notice_box">
          <div class="notice_title">巡检点</div>
          <div
              style="display: inline-block; vertical-align: middle"
              @click.stop="
              InspectionPointInfo.treePanelVisible =
                !InspectionPointInfo.treePanelVisible
            "
          >
            <div
                class="tree-wrap"
                style="--height: 40px; --icon-max-width: 36px"
            >
              <CSSelect height="40px" iWidth="36px" style="width: 226px">
                <div
                    v-if="pointNames.length > 0"
                    style="
                    width: 200px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                >
                  <span
                      v-for="(roomName, roomIndex) in pointNames"
                      :key="roomIndex"
                      style="margin-left: 10px; font-size: 24px"
                  >
                    {{ roomName }}
                  </span>
                </div>
                <div
                    v-else
                    style="
                    font-size: 20px;
                    color: #999;
                    margin-left: 10px;
                    font-size: 24px;
                  "
                >
                  请选择
                </div>
              </CSSelect>
              <TreePanel
                  v-show="InspectionPointInfo.treePanelVisible"
                  width="420px"
                  @loadMore="getInspectionPoint()"
              >
                <CSTree
                    :tree="InspectionPoint"
                    @change="InspectionPointInfo.onChangeRooms"
                >
                </CSTree>
              </TreePanel>
            </div>
          </div>
        </div>
        <div>
          <div class="notice_title" style="vertical-align: top">备注</div>
          <div
              class="edit-visiting-modal-content-item-content"
              style="width: 540px; display: inline-block"
          >
            <textarea
                v-model="addResAreaInfo.comment"
                maxlength="50"
                placeholder="限50个字"
                style="
                width: 718px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 198px;
                font-size: 24px;
                resize: none;
                padding-left: 10px;
              "
                type="number"
            ></textarea>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 查看备注对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="lookRemarksVisibel"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="备注"
        dialogWidth="580px"
        @onClose="lookRemarksVisibel = false"
    >
      <div
          slot="dialog-content"
          style="text-align: center; font-size: 24px; padding: 30px; color: #000"
      >
        {{ addResAreaInfo.comment }}
      </div>
    </CSDialog>
    <ViewImageModal/>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import ViewImageModal from "@/components/ViewImageModal";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import Pagination from "@/components/Pagination.vue";
import ChooseLocation from "@/components/ChooseLocation";

import {DEPARTMENT_TYPES, JOB_LEVEL} from "@/constant";

import {
  addResponsibilityArea,
  deleteResponsibilityArea,
  editResponsibilityArea,
  queryDepartmentStaffUrl,
  queryInspectionPointUrl,
  queryResponsibilityArea,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  name: "ResponsibilityArea",
  components: {
    CSTable,
    CSSelect,
    CSDialog,
    ViewImageModal,
    TreePanel,
    CSTree,
    Pagination,
    ChooseLocation,
  },
  data() {
    return {
      // 部门id
      DEPARTMENT_TYPES,
      // 等级
      JOB_LEVEL,
      filterHeight: 0,
      // 用户信息
      userInfo: this.$vc.getCurrentStaffInfo(),
      // 员工列表
      staffList: [],
      // 添加责任区对话框显隐
      lookResponsibilityAreaVisibel: false,
      // 查看备注对话框显隐
      lookRemarksVisibel: false,
      // 责任区列表数据
      resAreaList: [],
      // 巡检点页数
      InspectionPointpageNum: 0,
      // 查询责任区信息
      queryResAreaInfo: {
        regionCode: "", //区域code 必填
        search: "", //选填
        type: "", //选填类型 1.保洁;2.保安;3.工程;4.客服;5.其他
        principalUser: "", //负责人id 选填
        createUser: "", //创建人id 选填
        orgId: null  // 部门id
      },
      // 添加责任区信息
      addResAreaInfo: {
        regionCode: "", //区域code 必填
        name: "", //责任区名称  必填
        type: "", //类型 1.保洁;2.保安;3.工程;4.客服;5.其他   必填
        locationId: "", // 位置id
        principalUser: "", //负责人id  必填
        picture: [], //图片  必填
        pointIds: [], //巡检点   必填
        comment: "", //备注
      },
      checkedLocationName: "",
      pointNames: [],
      // 巡检点组件数据
      InspectionPointInfo: {
        treePanelVisible: false,
        onChangeRooms: (checkBoxInfo) => {
          const {key, source} = checkBoxInfo,
              checkbox = source[key];
          if (!checkbox?.children) {
            const index = this.addResAreaInfo.pointIds.indexOf(checkbox.id);
            if (index > -1) {
              checkbox.checked = false;
              this.pointNames.splice(index, 1);
              this.addResAreaInfo.pointIds.splice(index, 1);
            } else {
              checkbox.checked = true;
              this.addResAreaInfo.pointIds.push(checkbox.id);
              this.pointNames.push(checkbox.name);
            }
          } else {
            this.changeChildrenCheckState(
                checkbox,
                {checkedIds: this.addResAreaInfo.pointIds},
                !checkbox.checked
            );
          }
        },
      },
      // 类型
      responsibilityAreaType: {
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
          1: {id: 1, name: "保洁"},
          5: {id: 5, name: "其它"},
        },
        [DEPARTMENT_TYPES.SECURITY]: {
          2: {id: 2, name: "保安"},
          5: {id: 5, name: "其它"},
        },
        [DEPARTMENT_TYPES.ENGINEERING]: {
          3: {id: 3, name: "工程"},
          5: {id: 5, name: "其它"},
        },
        [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: {
          4: {id: 4, name: "客服"},
          5: {id: 5, name: "其它"},
        },
      },
      loadId: null,
      // 巡检点数组
      InspectionPoint: [],
      // 对话框标题
      dialogTitle: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    window.addEventListener("keydown", this.queryResAreaListDown);

    const departmentCode = this.userInfo.departmentCode;
    // 获取员工列表
    this.getStaffList(departmentCode);
    // 获取责任区列表
    //this.getResAreaList();
    this.getResAreaList();
    // 获取巡检点数组
    this.getInspectionPoint();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.getResAreaList(pageNo);
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryResAreaListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryResAreaListDown);
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    queryResAreaListDown(e) {
      if (e.keyCode == 13) {
        this.getResAreaList();
      }
    },
    /**
     * 修改位置
     * @param {Object} location 位置信息
     * */
    changeLocation(location) {
      this.addResAreaInfo.locationId = location.id;
    },
    // 获取责任区列表
    getResAreaList(pageNo = this.pageParams.pageNo, pageSize = this.pageParams.pageSize) {
      let JOB_LEVEL = this.$vc.getCurrentStaffInfo().jobLevel;
      let dutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
      if ([1001, 1002].includes(dutyType)) {
        this.queryResAreaInfo.orgId = null;
        let params = {
          ...this.queryResAreaInfo,
          ...this.pageParams,
          regionCode: this.$vc.getCurrentRegion().code
        };
        this.$fly.post(queryResponsibilityArea, params)
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              if (pageNo === 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total,
                  currentPage: pageNo,
                  pageSize: pageSize,
                });
              }
              this.resAreaList = res.data.datas;
            });
      } else if (Object.values(this.DEPARTMENT_TYPES).includes(dutyType) && JOB_LEVEL == 1) {
        this.queryResAreaInfo.orgId = Number(this.$vc.getCurrentStaffInfo().dutyType);
        let params = {
          ...this.queryResAreaInfo,
          ...this.pageParams,
          regionCode: this.$vc.getCurrentRegion().code
          // type: this.judgeType(),
        };
        this.$fly.post(queryResponsibilityArea, params)
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              if (pageNo === 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total,
                  currentPage: pageNo,
                  pageSize: pageSize,
                });
              }
              this.resAreaList = res.data.datas;
            });
      }
    },
    // 判断类型
    judgeType() {
      let dutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
      let type = "";
      if (this.queryResAreaInfo.type == 5) {
        return (type = 5);
      } else if ([1001, 1002].includes(dutyType)) {
        return (type = this.queryResAreaInfo.type);
      }
      switch (dutyType) {
        case this.DEPARTMENT_TYPES.PROCTER_GAMBLE:
          type = 1;
          break;
        case this.DEPARTMENT_TYPES.SECURITY:
          type = 2;
          break;
        case this.DEPARTMENT_TYPES.ENGINEERING:
          type = 3;
          break;
        case this.DEPARTMENT_TYPES.CUSTOMER_SERVICE:
          type = 4;
          break;
        default:
          break;
      }
      return type;
    },
    // 添加责任区按钮
    addResArea() {
      this.checkedLocationName = "";
      this.InspectionPoint.forEach((item) => {
        if (this.addResAreaInfo.pointIds.includes(item.id)) {
          item.checked = false;
        }
      });
      this.pointNames = [];
      this.dialogTitle = "添加责任区";
      this.addResAreaInfo = {
        regionCode: "",
        name: "",
        type: "",
        locationId: "",
        principalUser: "",
        picture: [],
        pointIds: [],
        comment: "",
      };
      this.lookResponsibilityAreaVisibel = true;
    },
    // 修改责任区按钮
    editResArea(resAreInfo) {
      if (!Array.isArray(resAreInfo.picture)) {
        resAreInfo.picture = JSON.parse(resAreInfo.picture);
      }
      let principalUser = null;
      this.pointNames = [];
      this.dialogTitle = "修改责任区";
      this.staffList.forEach((item) => {
        if (
            resAreInfo.principalUserName ==
            item.name + "【" + item.jobTitle + "】"
        ) {
          principalUser = item.id;

        }
      });
      let pointIds = JSON.parse(resAreInfo.pointIds);
      this.checkedLocationName = resAreInfo.position;
      this.addResAreaInfo = {
        regionCode: "",
        name: resAreInfo.name,
        type: resAreInfo.type,
        locationId: resAreInfo.locationId,
        principalUser,
        picture: resAreInfo.picture,
        pointIds,
        comment: resAreInfo.comment,
      };
      this.InspectionPoint.forEach((item) => {
        item.checked = false;
        if (this.addResAreaInfo.pointIds.includes(item.id)) {
          item.checked = true;
          this.pointNames.push(item.name);
        }
      });
      this.loadId = resAreInfo.id;
      this.lookResponsibilityAreaVisibel = true;
    },
    // 添加修改责任区
    addEditResArea() {
      if (!this.addResAreaInfoValidate()) {
        this.$vc.toast(this.$vc.validate.errInfo);
        return;
      }
      const params = {
        ...this.addResAreaInfo,
        regionCode: this.$vc.getCurrentRegion().code || "",
        picture: JSON.stringify(this.addResAreaInfo.picture),
        pointIds: JSON.stringify(this.addResAreaInfo.pointIds),
      };
      let url = "";
      console.log(this.addResAreaInfo.principalUser)
      if (this.loadId) {
        url = editResponsibilityArea;
        params.id = this.loadId;
      } else {
        url = addResponsibilityArea;
      }
      this.$fly.post(url, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.getResAreaList();
        this.lookResponsibilityAreaVisibel = false;
      });
    },
    // 删除责任区
    deleteResArea(id) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteResponsibilityArea, {
                regionCode: this.$vc.getCurrentRegion().code,
                id,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                this.getResAreaList();
              });
        },
      });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.staffList = res.data;
          });
    },
    // 获取巡检点数组
    getInspectionPoint() {
      let params = {
        regionId: this.$vc.getCurrentStaffInfo().regionCode,
        buildingId: "",
        orgId: "",
        keyword: "",
        operatorId: this.$vc.getCurrentStaffInfo().id,
        pageSize: 50,
        pageNum: ++this.InspectionPointpageNum,
      };
      this.$fly.post(queryInspectionPointUrl, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.InspectionPoint.push(
            ...res.data.datas.map((point) => {
              point.checked = false;
              return point;
            })
        );
      });
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 上传图片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },
    // 选择文件后处理
    chooseStaffPhoto(event) {
      console.log(event, "文件对象");
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        var reader = new FileReader(); //新建FileReader对象
        reader.readAsDataURL(file); //读取为base64
        reader.onloadend = async (e) => {
          const photo = await this.uploadPhoto(reader.result).catch((err) => {
            console.error("上传照片失败");
            return "";
          });
          this.addResAreaInfo.picture.push(photo);
          event.target.value = "";
        };
      }
    },
    deletePhotos(index) {
      this.addResAreaInfo.picture.splice(index, 1);
    },
    updateSensitivePhotos(src) {
      this.Announcement.photos.push(src);
    },
    // 判断类型权限
    judgmentAuthority: function (judgmentObj) {
      let udutyType = Number(this.userInfo.dutyType);
      let jobLevel = this.userInfo.jobLevel;
      let types = {};
      if ([1001, 1002, 1012].includes(udutyType)) {
        for (let firstKey in judgmentObj) {
          for (let secondKey in judgmentObj[firstKey]) {
            if (types[secondKey]) {
              continue;
            }
            types[secondKey] = judgmentObj[firstKey][secondKey];
          }
        }
        return types;
      } else if (jobLevel == 1) {
        switch (udutyType) {
          case this.DEPARTMENT_TYPES.PROCTER_GAMBLE:
            return judgmentObj[this.DEPARTMENT_TYPES.PROCTER_GAMBLE];
          case this.DEPARTMENT_TYPES.SECURITY:
            return judgmentObj[this.DEPARTMENT_TYPES.SECURITY];
          case this.DEPARTMENT_TYPES.ENGINEERING:
            return judgmentObj[this.DEPARTMENT_TYPES.ENGINEERING];
          case this.DEPARTMENT_TYPES.CUSTOMER_SERVICE:
            return judgmentObj[this.DEPARTMENT_TYPES.CUSTOMER_SERVICE];
          default:
            break;
        }
      }
    },
    // 查看图片
    lookImages(photos) {
      if (!Array.isArray(photos)) {
        this.$vc.emit(
            this.$route.path,
            "viewImageModal",
            "view",
            JSON.parse(photos)
        );
      } else {
        this.$vc.emit(this.$route.path, "viewImageModal", "view", photos);
      }
    },
    // 去巡检点二级页面
    toInspectionPoint(pointIds) {
      if (typeof pointIds == "string") {
        this.$router.push({path: "point", query: {pointIds}});
      } else {
        this.$router.push({
          path: "point",
          query: {pointIds: JSON.stringify(pointIds)},
        });
      }
    },
    // 查看备注
    lookRemarks(comment) {
      this.addResAreaInfo.comment = comment;
      this.lookRemarksVisibel = true;
    },
    // 校验表单
    addResAreaInfoValidate() {
      return this.$vc.validate.validate(
          {
            addResAreaInfo: this.addResAreaInfo,
          },
          {
            "addResAreaInfo.name": [
              {
                limit: "required",
                param: "",
                errInfo: "责任区名称不能为空",
              },
              {
                limit: "maxLength",
                param: "10",
                errInfo: "责任区名称不能超过10位",
              },
            ],
            "addResAreaInfo.type": [
              {
                limit: "required",
                param: "",
                errInfo: "类型不能为空",
              },
            ],
            "addResAreaInfo.locationId": [
              {
                limit: "required",
                parma: "",
                errInfo: "位置不能为空",
              },
            ],
            "addResAreaInfo.picture": [
              {
                limit: "required",
                param: "",
                errInfo: "图片不能为空",
              },
            ],
            "addResAreaInfo.pointIds": [
              {
                limit: "required",
                param: "",
                errInfo: "巡检点不能为空",
              },
            ],
          }
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.notice_box {
  margin-bottom: 23px;
}

.notice_name {
  width: 400px;
  height: 38px;
  font-size: 24px;
  padding-left: 10px;
  border: 1px solid #999;
  border-radius: 4px;
  vertical-align: middle;
}

.notice_title {
  display: inline-block;
  width: 120px;
  font-size: 24px;
  color: #000;
  text-align: right;
  margin-right: 40px;
  vertical-align: middle;
}

.add-addResArea-body {
  padding: 26px 18px 30px 18px;
}

.tree-wrap {
  max-width: 100%;
  position: relative;

  .cs-input {
    width: 215px;
  }
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
